<template>
	<div class="app-view">
		<van-nav-bar title="订单列表" left-text="返回" left-arrow @click-left="$routerGo(-1)" :fixed="true" />
		<van-list class="order-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="80">
            <div class="order-item" v-for="item in orderList" :key="item.id">
                <div class="title">
                    订单编号：{{ item.trade_parent_id }}
                </div>
                <van-card :title="item.item_title" :thumb="item.item_img" :desc="`店铺名称: ${item.seller_shop_title}`">
                    <template #tags>
                        <van-tag round type="primary" style="margin:5px 0;" v-if="item.tk_status==12">{{ orderStatus(item.tk_status) }}</van-tag>
                        <van-tag round type="success" style="margin:5px 0;" v-else-if="item.tk_status==3">{{ orderStatus(item.tk_status) }}</van-tag>
                        <van-tag round color="#ccc" style="margin:5px 0;" v-else-if="item.tk_status==13">{{ orderStatus(item.tk_status) }}</van-tag>
                        <van-tag round type="warning" style="margin:5px 0;" v-else>{{ orderStatus(item.tk_status) }}</van-tag>
                    </template>
                </van-card>
                <div class="detail" v-if="item.tk_paid_time">
                    <div class="inner">
                        <span>付款金额</span>
                        <div class="txt blod">{{ item.alipay_total_price }}</div>
                    </div>
                    <div class="inner">
                        <span>付款预估收入</span>
                        <div class="txt blod">&yen; {{ item.pub_share_pre_fee }}</div>
                    </div>
                    <div class="inner">
                        <span>提成</span>
                        <div class="txt blod">{{ item.tk_total_rate }} %</div>
                    </div>
                    <div class="inner">
                        <span>技术服务费</span>
                        <div class="txt">{{ Number(item.alimama_share_fee)> 0?item.alimama_share_fee:'--' }}</div>
                    </div>
                </div>
                <div class="time-wrap">
                    <span class="time" v-if="item.tk_paid_time">付款时间 {{ item.tk_paid_time }}</span>
                    <span class="time" style="text-align:right;" v-if="item.tk_earning_time">结算时间 {{ item.tk_earning_time }}</span>
                </div>
            </div>
        </van-list>
	</div>
</template>

<script>
export default {
	data() {
		return {
			orderList: [],
            pageindex: 1,
            page_size: 6,
            loading: false,
            finished: false,
            newTKL: '',
            showDialog: false,
		}
	},
	created() {
		this.onLoad()
	},
	methods: {
		onLoad() {
			if(this.finished) {
                this.loading = false;
				return false;
			}
            let params = { pageindex: this.pageindex, page_size: this.page_size }
            this.loading = true
            this.$ask.post('/getOrderList', params)
                .then(res => {
                    // 数据全部加载完成
                    if (!res.data || res.data.length == 0) {
                        this.finished = true;
                    }
                    else {
                        this.pageindex++;
						this.orderList = this.orderList.concat(res.data)
                    }
                    // 加载状态结束
                    this.loading = false;
                })
                .catch(this.$toastError)
        },
        orderStatus(status){
            let statusTxt = ''
            // 3：订单结算，12：订单付款， 13：订单失效，14：订单成功
            if( status == 3){
                statusTxt = '已结算'
            }
            else if(status == 12){
                statusTxt = '已付款'
            }
            else if(status == 13){
                statusTxt = '订单失效'
            }
            else if(status == 14){
                statusTxt = '订单成功'
            }
            else statusTxt = status
            return statusTxt
        }
	}
}
</script>

<style lang="scss" scoped>
.order-list{
    padding-top: 46px;
    .order-item{
        background: #fff;
        border-radius: 10px;
        margin: 10px 0;
        .title{
            padding: 12px 16px;
            font-size: 14px;
            border-bottom: 1px solid #eee;
        }
        .van-card{
            background: #fff;
            margin-top: 0;
            font-size: 14px;
            .van-card__desc{
                font-size: 12px;
                margin-top: 5px;
            }
        }
        .detail{
            display: flex;
            font-size: 12px;
            padding: 8px 16px 0;
            .inner{
                flex: 1;
                .txt{
                    line-height: 28px;
                    font-size: 14px;
                }
                .blod{
                    font-weight: bold;
                }
            }
        }
        .time-wrap{
            padding: 16px;
            font-size: 12px;
            display: flex;
            .time{
                flex:1;
            }
        }
    }
}
.copy-text {
    text-align: center;
    display: block;
    line-height: 28px;
    padding: 10px;
    color: #4fc08d;
}
</style>